@charset "UTF-8";
/* 
Author: Rémi Colnot
Project: wip-october
06/2018
*/
@font-face {
  font-family: "Akkurat";
  src: url("fonts/Akkurat.eot?") format("eot"), url("fonts/Akkurat.woff2") format("woff2"), url("fonts/Akkurat.woff") format("woff"), url("fonts/Akkurat.ttf") format("truetype"), url("fonts/Akkurat.svg#Akkurat") format("svg");
}

body {
  font-family: 'Akkurat';
  font-size: 1.33em;
  cursor: url(../../images/cursor-multiply.png), auto;
  background-color: #fffcf0;
}

.section {
  background-color: #fffcf0 !important;
}

.section div.section-container-intro {
  display: grid;
  height: 100%;
  text-align: center;
  grid-template-rows: 7em calc(100% - 14em) 2.75em 4.25em;
  padding: 0 7em;
}

.section div.section-container-intro div.intro-text {
  display: flex;
  align-items: center;
  grid-row: 2 / 3;
}

.section div.section-container-intro div.intro-text p {
  padding: 0.33333em;
  font-size: 3em;
  margin: 0 auto;
  text-align: left;
}

.section div.section-container-intro .brands-wrapper {
  display: flex;
  align-items: center;
  grid-row: 2 / 3;
}

.section div.section-container-intro .brands-wrapper ul {
  margin: 0 auto;
  text-align: left;
  display: grid;
  grid-row-gap: 0.3em;
  grid-template-columns: 1fr 1fr 2fr;
  /* 				li:nth-child(1n) {
					flex: 0 1 25%;
				}*/
  /*
				li:nth-child(3n) {
					flex: 0 0 50%;
				} */
}

.section div.section-container-intro .brands-wrapper ul li {
  white-space: nowrap;
}

.section div.section-container-intro .brands-wrapper ul li:nth-child(3n+2) {
  margin-right: 2em;
}

.section-project {
  display: grid;
  height: 100%;
  text-align: center;
  grid-template-rows: 7em calc(100% - 14em) 2.75em 4.25em;
}

.section-project div.project-title {
  display: flex;
  align-items: center;
}

.section-project div.project-title h3 {
  margin: 0 auto;
}

.section-project div.project-info-button svg {
  transition: all 0.8s;
}

.section-project div.project-info-button svg:hover {
  cursor: pointer;
}

.section-project div.project-info-button svg.active {
  transform: rotate(45deg);
}

/* -------------- */
/* Fixed elements */
/* -------------- */
nav, div.logo {
  position: fixed;
}

div.logo {
  z-index: 2;
  width: 100%;
  text-align: center;
}

@media (min-width: 560px) {
  div.logo {
    text-align: left;
    width: auto;
  }
}

nav {
  z-index: 1;
  padding: 0.5em 1em;
  width: 100%;
}

nav.hidden {
  opacity: 0.4;
  transition: all 0.7s;
}

nav.nav-active {
  height: 100%;
}

nav div.dropdown {
  display: flex;
  flex-direction: column;
}

nav div.dropdown div.dropdown-label {
  z-index: 3;
  height: 100%;
}

@media (min-width: 560px) {
  nav div.dropdown div.dropdown-label {
    display: none;
  }
}

nav div.dropdown div.dropdown-menu {
  display: none;
}

@media (min-width: 560px) {
  nav div.dropdown div.dropdown-menu {
    display: block;
    align-self: center;
  }
}

nav div.dropdown div.dropdown-menu ul {
  align-self: center;
  display: flex;
  flex-direction: column;
}

@media (min-width: 560px) {
  nav div.dropdown div.dropdown-menu ul {
    text-align: center;
  }
}

nav div.dropdown div.dropdown-menu ul li {
  flex: 1;
}

nav div.dropdown div.dropdown-center-helper {
  display: none;
  align-self: center;
}

nav div.dropdown div.dropdown-center-helper p {
  align-self: flex-end;
}

nav div.dropdown div.is-active {
  display: flex;
  flex: 1;
}

div.contact {
  position: fixed;
  top: 0;
  left: 0;
  padding: 7em 1em;
  display: none;
  height: 100%;
  writing-mode: vertical-lr;
  transition: all 0.7s;
}

div.contact.hidden {
  opacity: 0.4;
}

@media (min-width: 560px) {
  div.contact {
    display: flex;
  }
}

div.contact > div {
  flex: 1;
}

div.contact > div span, div.contact > div a {
  display: block;
}

div.contact > div.socials {
  display: flex;
  flex-direction: column;
}

div.contact > div.socials .flex-helper {
  align-self: flex-end;
}

/* -------- */
/* Projects */
/* -------- */
div.section-container {
  display: grid;
  height: 100%;
  text-align: center;
  grid-template-rows: calc(100% - 14em);
  padding: 0;
}

@media (min-width: 560px) {
  div.section-container {
    padding: 0 7em;
  }
}

div.section-container div.intro-text {
  display: flex;
  align-items: center;
  grid-row: 2 / 3;
}

div.section-container div.intro-text p {
  padding: 0.33333em;
  font-size: 3em;
  margin: 0 auto;
  text-align: left;
}

div.section-container div.project-image {
  display: flex;
}

div.section-container div.project-image > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

div.section-container div.project-image > div img {
  position: relative;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  opacity: 1;
  transition: all 0.8s;
}

div.section-container div.project-image > div img.hidden {
  opacity: 0;
}

div.section-container div.project-image > div .nav-buttons {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  z-index: 200;
}

div.section-container div.project-image > div .nav-buttons.next-button {
  left: 50%;
}

div.section-container div.project-image > div .nav-buttons.next-button:hover {
  cursor: url(../../images/cursor-multiply-next.png), auto;
}

div.section-container div.project-image > div .nav-buttons.previous-button:hover {
  cursor: url(../../images/cursor-multiply-prev.png), auto;
}

div.section-container div.project-image > div .project-details {
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.8s;
  display: flex;
  align-items: center;
  text-align: left;
}

div.section-container div.project-image > div .project-details.active {
  opacity: 1;
}

div.section-container div.project-image > div .project-details .project-details-wrapper {
  max-width: 35em;
  margin: 0 auto;
}

div.section-container div.project-image > div .project-details .project-description {
  margin-bottom: 1em;
}

/* -------- */
/*  Studio  */
/* -------- */
