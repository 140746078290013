/* 
Author: Rémi Colnot
Project: wip-october
06/2018
*/

@import "variables";
@import "mixins";

@include font-face(Akkurat, fonts/Akkurat);

body {
	font-family: 'Akkurat';
	font-size: 1.33em;
	cursor: url(../../images/cursor-multiply.png), auto;
	background-color: $bg-color;
}
.section {
	background-color: $bg-color!important;
	div.section-container-intro {
		display: grid;
		height: 100%;
		text-align: center;
		grid-template-rows: 7em calc(100% - 14em) 2.75em 4.25em;
		padding: 0 7em;
		div.intro-text {
			display: flex;
			align-items: center;
			grid-row: 2 / 3;
			p {
				padding: 0.33333em;
				font-size: 3em;
				margin: 0 auto;
				text-align: left;
			}
		}
		.brands-wrapper {
			display: flex;
			align-items: center;
			grid-row: 2 / 3;
			ul {
				margin: 0 auto;
				text-align: left;
				display: grid;
				grid-row-gap: 0.3em;
				//flex-wrap: wrap;
				//columns: 2;
				grid-template-columns: 1fr 1fr 2fr;
/* 				li:nth-child(1n) {
					flex: 0 1 25%;
				}*/
				li {
					white-space: nowrap;
				}
				li:nth-child(3n+2) {
					margin-right: 2em;
				}/*
				li:nth-child(3n) {
					flex: 0 0 50%;
				} */
			}
		}
	}
}
.section-project {
	display: grid;
	height: 100%;
	text-align: center;
	grid-template-rows: 7em calc(100% - 14em) 2.75em 4.25em;
	div.project-title {
		display: flex;
		align-items: center;
		h3 {
			margin: 0 auto;
		}
	}
	div.project-info-button {
		svg {
			transition: all 0.8s;
			&:hover {
				cursor: pointer;
			}
			&.active {
				transform: rotate(45deg);
			}
		}
	}
}

/* -------------- */
/* Fixed elements */
/* -------------- */

nav, div.logo {
	position: fixed;
}

div.logo {
	z-index: 2;
	width: 100%;
	text-align:center;
	@media  (min-width : 560px) {
		text-align: left;
		width: auto;
	}
}

nav {
	z-index: 1;
	padding: 0.5em 1em;
	width: 100%;
	&.hidden {
		opacity: 0.4;
		transition: all 0.7s;
	}
	&.nav-active {
		height: 100%;
	}
	div.dropdown {
		display: flex;
		flex-direction: column;
		div.dropdown-label {
			z-index: 3;
			height: 100%;
			@media  (min-width : 560px) {
				display: none;
			}
		}
		div.dropdown-menu {
			display: none;
			@media  (min-width : 560px) {
				display: block;
				align-self: center;
			}
			ul {
				align-self: center;
				display: flex;
				flex-direction: column;
				@media  (min-width : 560px) {
				text-align: center;
				}
				li {
					flex: 1;
				}
			}
		}
		div.dropdown-center-helper {
			display: none;
			align-self: center;
			p {
				align-self: flex-end;
			}
		}
		div.is-active {
			display: flex;
			flex:1;
		}
	}
}

// Contact 

div.contact {
	position: fixed;
	top: 0;
	left: 0;
	padding: 7em 1em;
	display: none;
	height: 100%;
	writing-mode: vertical-lr;
	transition: all 0.7s;
	&.hidden {
		opacity: 0.4;
	}
	@media  (min-width : 560px) {
		display: flex;
	}
	&>div {
		flex:1;
		span, a {
			display: block;
		}
		&.socials {
			display: flex;
			flex-direction: column;
			.flex-helper {
				align-self: flex-end;
			}
		}
	}
}

/* -------- */
/* Projects */
/* -------- */

// 1st section 

div.section-container {
	display: grid;
	height: 100%;
	text-align: center;
	//grid-template-rows: 7em calc(100% - 14em) 2.75em 4.25em;
	grid-template-rows: calc(100% - 14em);
	padding: 0;
	@media  (min-width : 560px) {
		padding: 0 7em;
	}
	div.intro-text {
		display: flex;
		align-items: center;
		grid-row: 2 / 3;
		p {
			padding: 0.33333em;
			font-size: 3em;
			margin: 0 auto;
			text-align: left;
		}
	}
	div.project-image {
		display: flex;
		&>div {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			height: 100%;
			img {
				position: relative;
				object-fit: contain;
				max-height: 100%;
				max-width: 100%;
				display: block;
				top: 50%;
				transform: translateY(-50%);
				margin: 0 auto;
				opacity:1;
				transition: all 0.8s;
				&.hidden {
					opacity:0;
				}
			}
			.nav-buttons {
				position: absolute;
				width: 50%;
				height: 100%;
				top: 0;
				z-index: 200;
				&.next-button {
					&:hover {
						cursor: url(../../images/cursor-multiply-next.png), auto;
					}
					left:50%;
				}
				&.previous-button:hover {
					cursor: url(../../images/cursor-multiply-prev.png), auto;
				}
			}
			.project-details {
				position: absolute;
				width: 100%;
				top:0;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
				transition: all 0.8s;
				display: flex;
				align-items: center;
				text-align: left;
				&.active {
					opacity:1;
				}
				.project-details-wrapper {
					max-width: 35em;
					margin: 0 auto;
				}
				.project-description {
					margin-bottom: 1em;
				}
			}
		}
	}
}

/* -------- */
/*  Studio  */
/* -------- */

div.studio-section {
	.studio-wrapper {
		
	}
}